<template>
  <b-container class="mt-10"  style="max-width: 915px !important;">
    <div class="text-center mx-auto">
      <h2 class="mb-3">{{ $t("listing.label.optionHead") }}</h2>
      <b-row>
        <!--<b-col style="max-width: 35px"
          ><b-icon
            icon="exclamation-circle-fill"
            font-scale="1"
            class="text-recommend"
          ></b-icon>
        </b-col>-->
        <b-col class="col-10 offset-1">
          <i18n tag="p" path="listing.label.optionSubHead">
            <!--<router-link to="">{{ $t("global.label.learnMore") }}</router-link>-->
          </i18n>
        </b-col>
      </b-row>
    </div>
    <TheListingPlanSelecter />
    <div class="question-buttons my-5 text-center">
      <h3 class="mb-3">{{ $t("listing.label.question") }}</h3>
      <b-button variant="outline-primary" class="m-2" @click="mailto">
        <i class="fa fa-at fa-lg text-primary-dark mr-2" aria-hidden="true"/>
        {{ $t("listing.button.email") }}
      </b-button>
      <b-button variant="outline-primary" class="m-2">
        <i
          class="fa fa-phone fa-lg text-primary-dark mr-2 links"
          aria-hidden="true"
        />
        {{ $t("listing.button.call") }}
      </b-button>
    </div>
    <!-- location: {{ l }} -->
  </b-container>
</template>

<script>
import axios from "axios";
import TheListingPlanSelecter from "@/components/listing/TheListingPlanSelecter";

export default {
  components: {
    TheListingPlanSelecter
  },
  data() {
    return { plans: [], l: null };
  },
  methods: {
    mailto(){
      window.location.href = "mailto:contact@pashmotors.com"
    },
    async getListingPlan() {
      try {
        const response = await axios.get("Listing/GetPlans");
        console.log(response.data);

        response.data.forEach(element => {
          let plan = [
            {
              title: element.Name,
              fee: element.Amount,
              currencyCode: element.CurrencyCode,
              description: element.Description,
              subtitle: element.Bonus,
              planId: element.PlanId
            }
          ];
          this.plans.push(plan);
        });
        this.$store.dispatch("listingPlan", this.plans);
      } catch (error) {
        console.log(error);
      }
    },
    async getlocation() {
      try {
        const response = await axios.post("Basic/Locations", {
          ParentId: 15
        });

        this.l = response.data;
      } catch (error) {
        console.log(error);
      }
    }
  },
  beforeMount() {
    this.getListingPlan();
    this.getlocation();
    this.$store.dispatch("setCurrency", "CAD");
    this.$store.dispatch("listingPlan", this.plans);
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      let u = vm.$store.getters.user;

      vm.$store.dispatch("listingAgreement", false);
      if (vm.$store.getters.country.Code !== "CA") {
        vm.$bvModal.show("registerCountryNotFound");
        vm.$router.back();
      } else if (!u) {
        vm.$bvModal.show("signin");
        vm.$router.back();
      } else if(!vm.$store.getters.userType.includes('Seller')){
        if(!u.IsEmailVerified){
          vm.$store.dispatch('resendEmail');
        }else {
          vm.$bvModal.show("beSellerModal")
        }
        //vm.$router.back();
      } else if (!vm.$store.getters.verifyForListing && !vm.$store.getters.doneRegister) {
        vm.$bvModal.show("sellerChecking");
        vm.$router.back();
      }
    });
  }
};
</script>

<style scoped lang="scss">
.question-buttons button:hover i {
  color: #fff !important;
}
.links{
  &:hover{
    background: #006ac3 !important;
    *{
      color: #fff !important;
    }
  }
}
</style>
